@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.App {
  font-family: 'Merriweather', serif;
  background-color: #FAF3E0;
  color: #000;
  padding: 20px;
  text-align: center;
  position: relative;
}

.language-selector {
  padding: 10px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.language-selector button {
  background-color: #F5F5F5;
  border: 1px solid #D1C6B9;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.language-selector button:hover {
  background-color: #E8E8E8;
}

.language-selector button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #D1C6B9;
}

.header {
  margin-top: 20px;
}

.header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 10px;
  line-height: 1.2;
}

.header p {
  font-size: 1.2rem;
  margin: 5px 0;
}

.our-story, .event-details, .discover-chile, .attire, .rsvp, .gift-list, .transportation {
  margin: 60px auto;
  padding: 20px;
  border: 1px solid #D1C6B9;
  max-width: 800px;
  background-color: #FFFFF0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.our-story h2, .event-details h2, .discover-chile h2, .attire h2, .rsvp h2, .gift-list h2, .transportation h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.story-image {
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 20px auto;
  object-fit: cover;
  border: 1px solid rgba(209, 198, 185, 0.5);
}

.discover-chile-item.centered-text {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.discover-chile-item:not(.centered-text) {
  padding-left: 20px;
  text-align: left;
}

.discover-chile-item:not(.centered-text)::before {
  content: none;
}

.discover-chile .adventure-text {
  margin-top: 20px;
  font-weight: bold;
}

.discover-chile ul {
  list-style-type: none;
  padding-left: 20px;
  text-align: left;
}

.discover-chile ul li {
  margin-bottom: 10px;
}
.discover-chile .images {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.discover-chile .images img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 1px solid rgba(209, 198, 185, 0.5);
}

/* ... (other unchanged CSS rules) ... */

/* Responsive adjustments */
@media (max-width: 768px) {
  .discover-chile .images {
    gap: 10px;
  }

  .discover-chile .images img {
    width: calc(50% - 5px);
    height: auto;
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .discover-chile .images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
  }

  .discover-chile .images img {
    width: calc(50% - 5px);
    height: 150px;
    max-width: none;
    object-fit: cover;
  }
}
a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.blue-link {
  color: #4682B4;
}

.no-bullets {
  list-style-type: none;
  padding: 0;
}

.rsvp-link {
  display: inline-block;
  background-color: #8FBC8F;
  color: #000;
  padding: 10px 20px;
  text-decoration: none;
  margin-top: 10px;
}

.rsvp-link:hover {
  background-color: #7CAA7C;
}

.attire .mood-bar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.attire .mood-color {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}

.attire .mood-color.ivory {
  background-color: #FFFFF0;
}

.attire .mood-color.olive {
  background-color: #8FBC8F;
}

.attire .mood-color.lemon {
  background-color: #fff44f;
}

iframe {
  max-width: 100%;
  border: none;
}

.attire-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.attire-image {
  width: calc(33.33% - 10px);
  max-width: 200px;
  height: auto;
  object-fit: cover;
  border: 1px solid rgba(209, 198, 185, 0.5);
}

.transportation-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.transportation-image {
  width: 200px;
  height: auto;
  object-fit: cover;
  border: 1px solid rgba(209, 198, 185, 0.5);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .attire-image {
    width: calc(50% - 10px);
    max-width: 150px; /* Reduced from 200px */
  }

  .transportation-images {
    flex-direction: column;
    align-items: center;
  }

  .discover-chile .images img {
    width: 150px; /* Reduced from 200px */
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .attire-image {
    width: calc(50% - 10px);
    max-width: 120px; /* Further reduced for smaller screens */
  }

  .discover-chile .images img {
    width: 120px; /* Further reduced for smaller screens */
  }

  .discover-chile .images {
    flex-direction: column;
    align-items: center;
  }

  .transportation-image {
    width: 150px; /* Reduced for consistency */
  }
}

